/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --primary-color: #ffffff;
  --secondary-gr-color: #a5d961;
  --third-gr-color: #c1ff72;
  --tertiary-color: #669999;
  --blue-color: #22313f; 
  --pink-color: #FFC0CB;
}

body {
  background: linear-gradient(
    109.6deg,
    rgb(0, 0, 0) 11.2%,
    rgb(11, 132, 145) 70%,
    rgb(0, 0, 0) 100%
  );
  color: var(--primary-color);
    font-family: "Cormorant Garamond", serif;
 
}

.logo img {
  height: auto;
  width: 150px;
}
/* icon for navigation when small screen */
.menu-icon {
  font-size: 30px;
  cursor: pointer;
  color: var(--primary-color);
}

.menu-icon:hover {
  color: var(--secondary-gr-color);
}

.navbar {
  transition: top 0.3s, background-color 0.3s;
}

.navbar.visible {
  top: 0;
}

.navbar.invisible {
  top: -40px; 
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links.visible {
  display: block;
  border-radius: 8px;
  border: 2px ridge var(--secondary-gr-color)
  
}

.nav-links li {
  margin: 0 10px;
  
}

.menu-icon {
  cursor: pointer;
}

/* when closed display none */
.nav-links {
  display: none;
}
.nav-links a:hover {
  color: var(--secondary-gr-color);
  text-decoration: underline;
  

}

@media (max-width: 768px) {
  .nav-links {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    flex-direction: column;
  }

  .nav-links.visible {
    display: flex;
    background: linear-gradient(
      109.6deg,
      rgb(0, 0, 0) 11.2%,
      rgb(11, 132, 145) 91.1%
    );
    color: var(--primary-color);
  }

  .nav-links.visible a:hover {
    color: var(--secondary-gr-color);
  }

  .nav-links li {
    width: 100%;
    text-align: center;
    padding: 15px 0;
  }

  .nav-links a {
    width: 100%;
    display: block;
     font-size: 20px;

  }
}

@media (min-width: 769px) {
  .menu-icon {
    display: none;
  }

  .nav-links {
    display: flex !important;
  }
  /* spaces nav apart */
  .nav-links li {
    padding: 0 15px;
  }

  .nav-links a {
    font-size: 20px;
  
  }
}




/* About section */



.transparent-box {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px ridge var(--third-gr-color);
  border-radius: 8px;
  text-align: center; /* Center text */
}

/* titles */
.my-name h2 {
  font-size: 42px;
  margin-bottom: 10px;
  font-family: "Cormorant Garamond", serif;
}

/* software engineer title */
h3 {
  margin: 20px;
  font-family: "Cormorant Garamond", serif;
}

p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: "Cormorant Garamond", serif;
}

/* Contact form section */




.textarea {
  height: 150px;
  color: var(--blue-color)
}

.button-37 {
  border: 2px ridge var(--third-gr-color);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--third-gr-color);
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 10px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-37:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .button-37 {
    padding: 10px 30px;
  }
}
.form-message:empty {
  display: none;
}
.form-message {
  border: 1px ridge var(--third-gr-color);
  background: radial-gradient(
    circle at 10% 20%,
    rgba(216, 241, 230, 0.46) 0.1%,
    rgba(233, 226, 226, 0.28) 90.1%
  );
  font-family: "Montserrat", sans-serif;
  margin-top: 20px;
   text-align: center;
  padding-top: 10px;
  border-radius: 8px;
  font-weight: bold;
}
h2.welcome-to-projects {
  text-align: center;
  padding-top: 200px;
  font-size: 45px;
  margin-bottom: 10px;

}

/* Footer section */
.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  
}

.social-media a {
  color: var(--primary-color);
  margin: 0 10px;
 
}
.social-media a:hover {
  color: var(--secondary-gr-color);
}

.social-media svg {
  width: 24px;
  height: 24px;
  
}
/* testimonial section */

.testimonials {
  padding-top: 100px;
}

.testimonial {
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px ridge var(--third-gr-color);
  max-width: 400px;
  text-align: center;
  margin: 0 10px;
}

.quote {
  font-size: 18px;
  margin-bottom: 15px;
}
.name {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--third-gr-color);
}

.title {
  font-style: italic;
  color: var(--blue-color);
}
h2.what-ppl-say {
  text-align: center;
  padding-top: 175px;
  font-size: 45px;
  color: var(--primary-color);
}

/* ScrollButton at bottom */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: var(--blue-color);
  color: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  border:2px ridge var(--third-gr-color);
}

.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top svg {
  width: 40px;
  height: 40px;
}
/* arrows */
.arrow-button {
  color: var(--primary-color); 
  font-size: 3rem; 
  position: absolute; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  top: 50%; 
  z-index: 10; 
  cursor: pointer; 
  background-color: transparent; 
  border: none; 
  transform: translateX(-100%) translateY(-50%); 
  left: 0; 
}
.arrow-button-two {
  color: var(--primary-color);
  font-size: 3rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  background: transparent;
  border: none;
  transform: translateX(100%) translateY(-50%);
  right: 0;
}
/* carousel styling */

.carousel-container {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
}
@media (min-width: 300px) and (max-width: 460px){
  .carousel-container{
    height: 50vh;
  }
}
@media (min-width: 461px) and (max-width: 500px){
  .carousel-container{
    height: 50vh;
  }
}
@media (min-width: 501px) and (max-width: 1000px) {
  .carousel-container{
    height: 70vh;
  }
}
@media (min-width: 1001px) and (max-width: 1200px) {
  .cards-container{
    height: 100vh;
  }
}

.cards-container {
    position: relative;
    width: 30rem;
    height: 30rem;
}
@media (min-width: 300px) and (max-width: 460px){
  .cards-container{
    width: 17rem;
    height: 17rem;
  }
}
@media (min-width: 461px) and (max-width: 500px) {
    .cards-container {
        width: 20rem;
        height: 20rem;
    }
}

@media (min-width: 501px) and (max-width: 1000px) {
    .cards-container {
        width: 25rem;
        height: 25rem;
    }
}


@media (min-width: 1001px) and (max-width: 1200px) {
    .cards-container {
        width: 28rem;
        height: 28rem;
    }
}


.card-title {
  font-size: 25px;
  font-weight: bold; 
  font-family: 'Cormorant Garamond", serif'
}
@media (min-width: 300px) and (max-width: 460px) {
  .card-title {
   font-size: 20px;
  }
}
@media (min-width: 461px) and (max-width: 500px) {
  .card-title{
    font-size: 25px;
  }
}
@media (min-width: 501px) and (max-width: 1000px) {
  .card-title{
    font-size: 28px;
  }
}
.card-img-wrapper {
  width: 100%;
  max-width: 50%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .card-img {
    max-width: 100%;
    height: 75%;
  }
  .card-img-wrapper{
    width: 100%;
  max-width: 80%;
  height: 60%;
  }
}
.custom-paragraph {
  margin-top: 1.5rem; 
  margin-bottom: 1rem; 
  font-size: 1.125rem; 
}
@media (min-width: 300px) and (max-width: 460px) { 
.custom-paragraph {
    font-size: 1rem;
      margin-top: 0rem; 
    margin-bottom: 0rem; 
    }
  }




